export enum AccountTypes {
  ReferralProgramProviderAdmin = 'Referral Program Provider Admin',
  SpecialtyProgramProviderAdmin = 'Specialty Program Provider Admin',
  SpecialtyProgramClinician = 'Specialty Program Clinician',
  SDoHProviderAdmin = 'SDoH Provider Admin',
  SDoH = 'SDoH',
  PostAcuteFacility = 'Post-Acute Facility',
  Physician = 'Physician',
  HospitalCMDischargePlanner = 'Hospital CM/Discharge Planner',
  HomeHealth = 'Home Health',
  HealthPlanCaseManager = 'Health Plan Case Manager',
  DMEPOSProvider = 'DMEPOS Provider',
  CHAPPProvider = 'CHAPP Provider',
  BillingOffice = 'Billing Office',
  AdminHomeHealth = 'Admin Home Health',
  Hospital = 'Hospital',
  PhysicianGroup = 'Physician Group',
  SkilledNursingFacility = 'Skilled Nursing Facility',
  STARs = 'STARs',
  STARsProviderAdmin = 'STARs Provider Admin',
  WCC = 'WCC',
  WCCProviderAdmin = 'WCC Provider Admin',
}
