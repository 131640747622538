<div class="flex justify-content-center" *ngIf="isLoading">
  <p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
</div>
<div *ngIf="!isLoading">
  <div class="flex align-items-center mb-3">
    <button
      type="button"
      pButton
      class="p-button-text p-button-secondary p-button-action"
      (click)="goBack()"
    >
      <i class="pi pi-arrow-left icon-color p-button-icon-left"></i>
    </button>
    <div>
      <strong>{{ user?.firstName }} {{ user?.lastName }}</strong>
      <p class="{{ user?.isActive ? 'active' : 'inactive' }}">
        <span>{{ user?.isActive ? 'Active User' : 'Inactive User' }}</span>
      </p>
    </div>
    <!-- <div class="ml-3"> -->
      <button
        type="button"
        pButton
        class="ml-3"
        label="{{ !user?.isActive ? 'Activate User' : 'Deactivate User' }}"
        [appChangeUserStatus]="user"
        [accessEnableSubmit]="['ExternalUser.Write']"
      >
        <i class="pi pi-power-off icon-color p-button-icon-left"></i>
      </button>
    <!-- </div> -->
     
    <button type="button"
          pButton
          *ngIf="hasDevices"
          class="ml-auto p-button-outlined"
          label="Reset MFA Device"
          (click)="deleteDevices()">
      <i class="pi pi-refresh icon-color p-button-icon-left"></i>
    </button>
  </div>
  <div class="mb-5">
    <p-table [value]="users"
      class="table-parameters table-simple-paginator"
      [alwaysShowPaginator]="false">
      <ng-template pTemplate="header">
        <tr>
          <th>Health Plan</th>
          <th>Account Type</th>
          <th>Created On / Modified On</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ user?.healthPlans?.join(', ') }}</td>
          <td>{{ user?.accountType }}</td>
          <td>{{ dateTime | utcToLocal }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
<div *ngIf="showWarningMessage" class="mb-4 mt-0">
  <div class="p-message p-message-sm p-message-warn custom-width">
      <i class="pi pi-exclamation-triangle custom-icon"></i>
      <span class="p-message-text">
        <strong>Unable to Modify Health Plan or Account Type</strong><br>
          This is an SSO account and changes cannot be made to this account.
      </span>
  </div>
</div>
 

  <div class="mb-3">
    <form [formGroup]="form">
      <div class="p-form-widget grid p-fluid pl-2">
        <div class="col-4">
          <div class="field">
            <label class="field-required" >First Name :</label>
            <div class="p-field-value">
              <input pInputText type="text" formControlName="givenName" maxlength="50" 
              />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="field">
            <label class="field-required">Last Name:</label>
            <div class="p-field-value">
              <input pInputText type="text" formControlName="surname" maxlength="50" />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="field">
            <label>Business Email:</label>
            <div class="p-field-value">
              <input pInputText type="text" formControlName="otherMails" maxlength="50" />
            </div>
          </div>
        </div>
        <div class="col-4 p-fluid">
          <div class="field">
            <label class="field-required">Business Fax:</label>
            <div class="p-field-value">
              <p-inputMask
                mask="(999) 999-9999"
                formControlName="facsimileTelephoneNumber"
                [unmask]="true"
                [autoClear]="false"
              ></p-inputMask>
            </div>
          </div>
        </div>
        <div class="col-4 fluid">
          <div class="field">
            <label class="field-required">Business Phone:</label>
            <div class="p-field-value">
              <p-inputMask
                mask="(999) 999-9999"
                formControlName="telephoneNumber"
                [unmask]="true"
                [autoClear]="false"
              ></p-inputMask>
            </div>
          </div>
        </div>
        <div class="col-4 fluid">
          <div class="field">
            <label>Phone Number Extension:</label>
            <div class="p-field-value">
              <input pInputText type="text" formControlName="telephoneNumberExtension" />
              <small class="p-error" *ngIf="form?.controls['telephoneNumberExtension']?.hasError('maxlength')">
                Must be up to 9 characters long
              </small>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="flex justify-content-end">
    <ng-container *accessView="['ExternalUser.Write']">
      <button
        type="button"
        pButton
        label="Cancel"
        (click)="onCancel()"
        class="p-button-text p-button-secondary p-button-action p-mr-2"
        [disabled]="!form.dirty || !isFormChanged"
      ></button>
      <button
        type="submit"
        pButton label="Save"
        (click)="onSave()"
        class="p-button-action"
        [disabled]="canSaveUser"
        ></button>
    </ng-container>
  </div>
</div>
<!-- <p-panel
  *ngIf="!isLoading"
  header="User Profile"
  [showHeader]="false"
  [toggleable]="true"
  >

</p-panel> -->

<p-confirmDialog
  header="Confirmation"
  appendTo="body"
  [rejectIcon]="''"
  [acceptIcon]="''"
  [rejectButtonStyleClass]="'p-button-secondary'"
>
</p-confirmDialog>
<p-toast key="saved" position="bottom-left"></p-toast>

<p-confirmDialog
  key="unsavedChanges"
  appendTo="body"
  rejectIcon="''"
  acceptIcon="''"
  rejectButtonStyleClass="p-button-secondary"
  acceptButtonStyleClass="p-button-warning"
  [style]="{width: '398px'}"
></p-confirmDialog>

