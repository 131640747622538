<div *ngIf="disableRadioButtons">
  <div class="col-fixed field health-plan-dd-width">
    <label>Account Type:</label>
    <span>  Health Plan Case Manager</span>
  </div>
</div>
<div *ngIf="!disableRadioButtons" class="flex col-fixed py-2">
  <strong>Account Type: </strong>
  <form [formGroup]="accountSwitchForm" >
    <span class="ml-3" *ngFor="let type of availableAccountTypes">
      <p-radioButton
        name="accountTypeControl"
        formControlName="accountTypeControl"
        label="{{type.label}}"
        class="pb-2"
        [inputId]="type.value"
        [value]="type.value"
        [accessFormField]="['ExternalUser.Write']"
        (onClick)="accountTypeChange($event)">
      </p-radioButton>
    </span>
  </form>
</div>
<p-toast key="warn" position="bottom-left"></p-toast>
<p-toast key="switched" position="bottom-left"></p-toast>
<p-dialog
  header="Update Account Type"
  [(visible)]="showDialog"
  [modal]="true"
  appendTo="body"
  [closable]="false"
  [resizable]="false"
  [ngStyle]="{'width': '400px'}"
  [minY]="70"
  [maximizable]="false"
>
  <p>Are you sure that you want to update the Account Type?</p>
  <p-footer>
    <button
      type="button"
      pButton
      icon=""
      class="p-button-padding"
      (click)="proceedUpdate()"
      label="Yes"
    ></button>
    <button
      type="button"
      pButton
      icon=""
      (click)="cancelUpdate()"
      label="No"
      class="p-button-secondary p-button-padding"
    ></button>
  </p-footer>
</p-dialog>
