<p-accordion class="mb-3">
  <p-accordionTab header="User Account Information" [selected]="true">
    <div class="flex justify-content-center" *ngIf="isLoading">
      <p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
    </div>
    <div *ngIf="disableRadioButtons">
      <div class="col-fixed field health-plan-dd-width">
        <label>Health Plan:</label>
        <span>  Regence</span>
      </div>
    </div>
    <div *ngIf="!isLoading">
      <div *ngIf="!disableRadioButtons">
      <div class="col-fixed field health-plan-dd-width">
        <label>Health Plan:</label>
        <p-dropdown
          class="ml-3"
          [options]="applicableHealthPlans"
          [(ngModel)]="healthPlan"
          (onChange)="onChangeHealthPlan($event.value)"
          placeholder="Select Item"
          appendTo="body"
        >
        </p-dropdown>
      </div>
      </div>
      <app-account-switch
        [accountTypes]="applicableAccountTypes"
        [account]="accountType"
        [hasSelectedProviders]="hasSelectedProviders"
        [userId]="userId"
        [tenantId]="healthPlan?.tenantId"
        (accountTypeNew)="onChangeAccountType($event)"
        (showCentralizedDepartmentToggleForHP)="showCentralizedDepartmentToggleForHP = $event"
        [disableRadioButtons]="disableRadioButtons"
      ></app-account-switch>
    </div>

    <app-centralized-department-toggle
      *ngIf="shouldShowCentralizedDepartmentToggle && showCentralizedDepartmentToggleForHP"
      [userId]="userId"
      [currentCompany]="centralizedDepartment"
      [accountType]="accountType"
    ></app-centralized-department-toggle>

    <app-assign-providers-to-user
      *ngIf="showAssignProvidersWidget"
      [tenantId]="healthPlan?.tenantId"
      [userId]="userId"
      [accountType]="accountType"
      (selectedProviders)="onSelectProvider($event)"
      (userProvidersChanged) ="onUserProvidersChanged()"
    ></app-assign-providers-to-user>
  </p-accordionTab>
</p-accordion>
