import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@core/config/config.service';
import { UrlConstants } from '@core/constants';
import { HttpUtility } from '@core/http';
import { AccountTypeUpdate, Tenant } from '@core/model';
import { Observable } from 'rxjs';

@Injectable()
export class TenantsService {
  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(
    private httpUtility: HttpUtility,
    @Inject(API_URL) private readonly apiBaseUrl: string
  ) {}

  getTenants(): Observable<Tenant[]> {
    return this.httpUtility
      .get<Tenant[]>(`${this.apiBaseUrl}${UrlConstants.tenants}`);
  }

  getTenantsForRegistration(): Observable<Tenant[]> {
    return this.httpUtility
      .get<Tenant[]>(`${this.apiBaseUrl}${UrlConstants.tenantsForRegistration}`);
  }

  updateAccountType(userId: string, data: AccountTypeUpdate): Observable<null> {
    const url = `${this.apiBaseUrl}${UrlConstants.users}/${userId}/accountType`;
    return this.httpUtility.put<null>(url, data);
  }
}
