import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccountType, IExternalUserListItem, IProviderSearchResult } from '@core/model';
import { AccountTypeInfo } from '@core/model/accountTypeInfo';
import { UsersService } from '@core/services';
import { DateUtilities, ODataService, TableColumn } from '@mynexus/mynexus-ui-lib';
import { UtilitiesService } from '@core/services/utilities.service';
import { cloneDeep, isEmpty } from 'lodash-es';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-external-users-list',
  templateUrl: './external-users-list.component.html',
  styleUrls: ['./external-users-list.component.scss']
})
export class ExternalUsersListComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: true }) dataTable!: Table;

  rowsPerPage: number = 10;
  readonly defaultSortField = 'createdOn';
  readonly defaultSortOrder = 1;
  readonly defaultSortOrderOnLoad = -1;
  private subs = new SubSink();

  readonly defaultFilters: LazyLoadEvent = {
    first: 0,
    rows: this.rowsPerPage,
    sortField: this.defaultSortField,
    sortOrder: this.defaultSortOrderOnLoad,
    filters: {}
  };
  cols: TableColumn[];
  isLoading = true;
  totalRecords = 0;
  users: IExternalUserListItem[] = [];

  email: string[] = [];
  displayName: string[] = [];
  healthPlans: string[] = [];
  accountType: string[] = [];
  active!: boolean | null;
  createdOnRange: Date[] | null = null;
  tableFilters!: LazyLoadEvent;

  accountTypes?: AccountTypeInfo = {};

  emailsList: (string | null)[] = [];
  filteredEmailsList: (string | null)[] = [];
  displayNamesList: (string | null)[] = [];
  filteredDisplayNamesList: (string | null)[] = [];
  activesList: SelectItem[] = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private oDataService: ODataService,
    private router: Router,
    private messageService: MessageService,
    private utilitiesService: UtilitiesService
  ) {
    this.cols = [
      new TableColumn('', ''),
      new TableColumn('Business Email', 'email', '14rem'),
      new TableColumn('Display Name', 'displayName'),
      new TableColumn('Health Plan', 'healthPlans'),
      new TableColumn('Account Type', 'accountType'),
      new TableColumn('Active', 'isApproved'),
      new TableColumn('Created On', 'createdOn')
    ];
  }

  ngOnInit(): void {
    this.getAccountTypeInfo();
    this.subs.sink = this.usersService.getExternalUsersRelevantData()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.emailsList = res.emails;
        this.displayNamesList = res.displayNames;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subs.unsubscribe();
  }

  resetFilters(): void {
    this.dataTable.filters = {};
    this.tableFilters = this.defaultFilters;
    this.dataTable.clear();
    this.dataTable.sort({ field: this.defaultSortField });
    this.email = [];
    this.displayName = [];
    this.healthPlans = [];
    this.accountType = [];
    this.active = null;
    this.createdOnRange = null;
  }

  // tslint:disable-next-line: no-any
  filterTable(value: any, criteria: string, matchMode: string): void {
    this.dataTable.filter(value, criteria, matchMode);
  }

  loadData(filters: LazyLoadEvent): void {

    if (!filters.sortField) {
      filters.sortField = this.defaultSortField;
      filters.sortOrder = this.defaultSortOrderOnLoad;
    }

    this.dataTable.sortField = filters.sortField;
    // this copy is made because of modifying date range for query
    const copyOfFilters = cloneDeep(filters);

    if (copyOfFilters.sortField === 'healthPlans') {
      copyOfFilters.sortField = 'healthPlans/$count';
    } else {
      copyOfFilters.sortField = filters.sortField;
    }

    this.tableFilters = filters;
    this.checkDateRange(copyOfFilters.filters);

    var filtersData = copyOfFilters.filters ?? {};
    var queryParams:Record<any, any> = {
      take: copyOfFilters.rows,
      skip: copyOfFilters.first,
      isExternal: true,
      orderByDescending: filters.sortOrder && filters.sortOrder > 0 ? false : true,
      orderBy: filters.sortField
    };
    Object.keys(filtersData).forEach(propertyName => {
        const filterField = filtersData[propertyName];

        //Date field sends data as an array for start/end time.
        if(propertyName === "createdOn"){
          queryParams.createdDateStartDate = filterField.value[0];
          queryParams.createdDateEndDate = filterField.value[1];
        }
        else{
          queryParams[propertyName] = filterField.value;
        }
     });
    
    var queryString = new URLSearchParams(queryParams).toString();
    this.getUsers(queryString);
  }

  getUserAccountTypeToDisplay(user: IExternalUserListItem): string {
    if (!user?.accountType ) {
      return '';
    } else if (!this.accountTypes || !this.accountTypes[user.accountType]) {
      return user.accountType;
    } else {
      return this.accountTypes[user.accountType].displayName ?? this.accountTypes[user.accountType].businessType;
    }
  }

  getUsers(queryParams: string): void {
    this.isLoading = true;
    this.users = [];
    this.subs.sink = this.usersService.getExternalUsers(queryParams)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false)),
        map(res => {
          res.items = res.items.map(item => {
            item.accountTypeDisplayName = this.getUserAccountTypeToDisplay(item);
            
            return item;
          })

          return res
        }),
      )
      .subscribe({
        next: (res: IProviderSearchResult<IExternalUserListItem>) => {
          if (res) {
            this.users = res.items;
            this.totalRecords = res.count;
            this.dataTable.expandedRowKeys = {};
          }
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            detail: error.message,
            life: 5000
          });
        }}
      );
  }

  onSearchEmails(ev: {query: string}): void {
    const query = ev.query.toLocaleLowerCase();
    this.filteredEmailsList = this.emailsList.filter(item => item?.toLocaleLowerCase().indexOf(query) !== -1).splice(0, 100);
  }

  onSearchDisplayName(ev: {query: string}): void {
    const query = ev.query.toLocaleLowerCase();
    this.filteredDisplayNamesList = this.displayNamesList.filter(item => item?.toLocaleLowerCase().indexOf(query) !== -1).splice(0, 100);
  }

  editUser(userId: string): void {
    this.router.navigate(['users', userId, 'details']);
  }

  private isFilterEmpty(): boolean {
    return isEmpty(this.tableFilters.filters);
  }

  isClearAllEnabled(): boolean {
    const isSortApplied = !(
      this.dataTable.sortOrder !== this.defaultSortOrderOnLoad ||
      this.dataTable.sortField !== this.defaultSortField
    );

    return isSortApplied && this.isFilterEmpty();
  }

  checkDateRange(filters: any): void {
    Object.keys(filters).forEach(key => {
      if (key === 'createdOn') {
        const filterValue = filters[key].value;
        filterValue[0] = DateUtilities.convertToUTCStartOfDayDate(filterValue[0])?.toISOString();

        if (filterValue[0] && !filterValue[1]) {
          filterValue[1] = DateUtilities.convertToUTCEndOfDayDate(filterValue[0])?.toISOString();
        } else {
          filterValue[1] = DateUtilities.convertToUTCEndOfDayDate(filterValue[1])?.toISOString();
        }
      }
    });
  }

  private getAccountTypeNames(accountTypes: AccountType[]): AccountTypeInfo {
    const result: AccountTypeInfo = {};
    
    accountTypes.forEach(bt => result[bt.businessType] = bt);
    
    return result;
  }

  private getAccountTypeInfo() {
    this.subs.sink = this.utilitiesService.accountTypes
      .subscribe(res => this.accountTypes = this.getAccountTypeNames(res));
  }
}
