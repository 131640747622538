import { inject, Injectable } from '@angular/core';
import { API_URL } from '@core/config/config.service';
import { UrlConstants } from '@core/constants';
import { HttpUtility } from '@core/http';
import { BusinessType } from '@mynexus/mynexus-ui-lib';
import { ConfirmationService } from 'primeng/api';
import { Observable, shareReplay } from 'rxjs';

@Injectable()
export class UtilitiesService {
  private httpUtility = inject(HttpUtility);
  private apiBaseUrl = inject(API_URL);

  hasUnsavedChanges = false;
  private cache$!: Observable<BusinessType[]>;

  constructor(private confirmService: ConfirmationService) { }

  get accountTypes() {
    if (!this.cache$) {
      this.cache$ = this.fetchAccountTypes().pipe(shareReplay(1));
    }

    return this.cache$;
  }

  private fetchAccountTypes(): Observable<BusinessType[]> {
    const url = `${this.apiBaseUrl}${UrlConstants.getBusinessTypes}`;

    return this.httpUtility.get<BusinessType[]>(url);
  }

  confirmUnsavedChangesNavigation(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.confirmService.confirm({
        key: 'unsavedChanges',
        header: 'Your changes will be lost',
        message: `You have unsaved changes that will be lost.
        Are you sure you want to continue?`,
        rejectLabel: 'Back',
        acceptLabel: 'Discard Changes',
        accept: resolve,
        reject,
      });
    })
  };
}
