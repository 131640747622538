import { Inject, Injectable } from '@angular/core';
import { UrlConstants } from '@core/constants';
import { HandleError, HttpErrorHandler, HttpUtility } from '@core/http';
import { Observable, catchError } from 'rxjs';
import { ParentCompany } from './models/centralized-department.model';
import { CID_ELIGIBLE_TENANT } from './models/cid-eligible-tenant.const';
import { API_URL } from '@core/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class CentralizedDepartmentService {
  public handleError: HandleError;

  private readonly cidTenant = CID_ELIGIBLE_TENANT;

  constructor(
    private httpUtility: HttpUtility,
    private httpErrorHandler: HttpErrorHandler,
    @Inject(API_URL) private readonly apiBaseUrl: string) {
    this.handleError = this.httpErrorHandler.createHandleError('CentralizedDepartmentService');
  }

  public getCompaniesList(userId: string): Observable<ParentCompany[]> {
    const url = `${this.apiBaseUrl}${UrlConstants.users}/${userId}/ParentCompanies/${this.cidTenant}`;
    return this.httpUtility
      .post<ParentCompany[]>(url, '?$filter=branchProvidersCount gt 1')
      .pipe(catchError(this.handleError('getCompaniesList', {} as ParentCompany[])));
  }

  public setCompany(userId: string, companyName: string): Observable<boolean> {
    const url = `${this.apiBaseUrl}${UrlConstants.users}/${userId}/SetCentralizedDepartment/${this.cidTenant}`;
    return this.httpUtility
      .put<boolean>(url, companyName)
      .pipe(catchError(this.handleError('setCompany', {} as boolean)));
  }

  public unsetCompany(userId: string, companyName: string): Observable<boolean> {
    const url = `${this.apiBaseUrl}${UrlConstants.users}/${userId}/UnsetCentralizedDepartment/${this.cidTenant}`;
    return this.httpUtility
      .put<boolean>(url, companyName)
      .pipe(catchError(this.handleError('unsetCompany', {} as boolean)));
  }
}
