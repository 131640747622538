import { AccountTypeInfo } from "@core/model/accountTypeInfo";
import { AccountType } from "@core/model/tenant";
import { SelectItem } from "primeng/api";

export function getAccountTypeOptions( accountTypes: AccountType[]): SelectItem<any>[] {
    return accountTypes.map(bt => ({
        label: bt.displayName ? bt.displayName : bt.businessType,
        value: bt.businessType
      }));

}

export function getAccountTypeNames(accountTypes: AccountType[]): AccountTypeInfo {
  const result: AccountTypeInfo = {};
  accountTypes.forEach(bt => result[bt.businessType] = bt);
  return result;
}