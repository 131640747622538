import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { CentralizedDepartmentService } from './centralized-department.service';
import { Observable, catchError, finalize, of } from 'rxjs';
import { ParentCompany } from './models/centralized-department.model';
import { ConflictErrorMessage, GeneralErrorMessage } from '@core/constants/error-messages.const';

@Component({
  selector: 'app-centralized-department-toggle',
  templateUrl: './centralized-department-toggle.component.html',
  styleUrls: ['./centralized-department-toggle.component.scss']
})
export class CentralizedDepartmentToggleComponent implements OnChanges {
  @Input() userId!: string;
  @Input() currentCompany!: string | null;
  @Input() accountType!: string;

  public isLoading: boolean = false;
  public isToggleDisabled: boolean = false;
  public companiesOptions: SelectItem[] = [];
  public isCentralizedDepartmentControl = new FormControl(false);
  public companyControl = new FormControl<string | null>(null);


  @ViewChild('companyDropdown', { static: false }) companyDropdown?: Dropdown;

  constructor(
    private readonly centralizedDepartmentService: CentralizedDepartmentService,
    private readonly messageService: MessageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { accountType, currentCompany } = changes;

    if (accountType && !accountType.firstChange) {
      this.onAccountTypeChange();
    }

    if (currentCompany) {
      this.getCompaniesList();
    }
  }

  toggleSwitchChanged() {
    if (!this.isCentralizedDepartmentControl.value) {
      this.unsetCompany();
      this.resetCompanyControl();
      return;
    }
    this.focusCompanyDropdown();
  }

  onCompanyControlBlur() {
    this.companyControl.setValidators([Validators.required]);
    this.companyControl.markAsDirty();
    this.companyControl.updateValueAndValidity();
  }

  setCompany() {
    if (!this.companyControl.value) {
      return;
    }
    this.centralizedDepartmentService
      .setCompany(this.userId, this.companyControl.value)
      .pipe(catchError(error => this.showErrorMessage(error.status)))
      .subscribe(res => {
        if (res) this.currentCompany = this.companyControl.value;
      });
  }

  private unsetCompany() {
    if (!this.currentCompany) {
      return;
    }
    this.centralizedDepartmentService
      .unsetCompany(this.userId, this.currentCompany)
      .pipe(catchError(error => this.showErrorMessage(error.status)))
      .subscribe(res => {
        if (res) this.currentCompany = null;
      });
  }

  private onAccountTypeChange() {
    this.isCentralizedDepartmentControl.setValue(false);
    this.toggleSwitchChanged();
  }

  private getCompaniesList() {
    this.isLoading = true;
    this.centralizedDepartmentService.getCompaniesList(this.userId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((companies: ParentCompany[]) => this.setCompanyData(companies));
  }

  private setCompanyData(companies: ParentCompany[] = []) {
    this.isToggleDisabled = companies.length === 0;
    this.companiesOptions = companies.map((company: ParentCompany) => ({
      label: company.parentCompany,
      value: company.parentCompany
    }));
    this.isCentralizedDepartmentControl.setValue(!!this.currentCompany);
    this.companyControl.setValue(this.currentCompany);
  }

  private focusCompanyDropdown() {
    setTimeout(() => {
      if (!this.companyDropdown?.focused) {
        this.companyDropdown?.focus();
        this.companyDropdown?.show();
      }
    });
  }

  private resetCompanyControl() {
    this.companyControl.setValue(null);
    this.companyControl.setErrors(null);
    this.companyControl.markAsUntouched();
    this.companyControl.markAsPristine();
  }

  private showErrorMessage(code: number): Observable<boolean> {
    const isConflict = code === 409;
    this.messageService.add({
      severity: isConflict ? 'warn' : 'error',
      summary: isConflict ? 'Warning' : 'Error',
      detail: isConflict ? ConflictErrorMessage : GeneralErrorMessage,
      life: 5000
    });
    return of(false);
  }
}
