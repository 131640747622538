import { inject, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard implements CanDeactivate<boolean> {
  utilitiesService = inject(UtilitiesService);

  async canDeactivate(): Promise<boolean> {
    if (this.utilitiesService.hasUnsavedChanges) {
      try {
        await this.utilitiesService.confirmUnsavedChangesNavigation();
        this.utilitiesService.hasUnsavedChanges = false;

        return true;
      } catch {
        return false;
      }
    }

    return true;
  }
}
