<div class="flex justify-content-between align-items-center pb-3">
  <h5 class="my-2">Manage External Users</h5>
  <button
    *ngIf="dataTable"
    pButton
    type="button"
    class="p-button-warning p-button-rounded"
    label="Clear Filter Selections"
    [disabled]="isClearAllEnabled()"
    (click)="resetFilters()">
  </button>
</div>
<p-table
  #dt
  dataKey="email"
  class="table-parameters table-paginator"
  [value]="users"
  [columns]="cols"
  [paginator]="true"
  [(rows)]="rowsPerPage"
  [totalRecords]="totalRecords"
  [lazy]="true"
  [filterDelay]="1000"
  (onLazyLoad)="loadData($event)"
  [loading]="isLoading"
  [sortOrder]="defaultSortOrderOnLoad"
  [defaultSortOrder]="defaultSortOrder"
  [sortField]="defaultSortField"
  resetTablePageOnSort
  [alwaysShowPaginator]="false"
  [rowsPerPageOptions]="[5, 10, 20]"
  responsiveLayout="scroll"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
>
  <!-- <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width" />
    </colgroup>
  </ng-template> -->

  <ng-template pTemplate="header" let-columns>
    <!-- <tr class="header-sorting"> -->
    <tr>
      <th style="width: 122px"></th>
      <th pSortableColumn="email">
        <!-- <div class="table-header-sort"> -->
          Business Email
          <p-sortIcon
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
            ariaLabel="Activate to sort"
            field="email"
          >
          </p-sortIcon>
        <!-- </div> -->
      </th>
      <th pSortableColumn="displayName">
        <!-- <div class="table-header-sort"> -->
          Display Name
          <p-sortIcon
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
            ariaLabel="Activate to sort"
            field="displayName"
          >
          </p-sortIcon>
        <!-- </div> -->
      </th>
      <th>
        <!-- <div class="table-header-sort"> -->
          Health Plan
        <!-- </div> -->
      </th>
      <th>
        <!-- <div class="table-header-sort"> -->
          Account Type
        <!-- </div> -->
      </th>
      <th>
          Active
      </th>
      <th pSortableColumn="createdOn">
        <!-- <div class="table-header-sort"> -->
          Created On
          <p-sortIcon
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
            ariaLabel="Activate to sort"
            field="createdOn"
          >
          </p-sortIcon>
        <!-- </div> -->
      </th>
    </tr>

    <!-- <tr class="header-filter"> -->
    <tr class="header-filter">
      <th style="width: 122px"></th>
      <th>
        <div class="relative autocomplete-icon-align">
          <div class="search-icon absolute" (click)="emailsLisInput.focusInput()">
            <i class="pi pi-search"></i>
          </div>
          <p-autoComplete
            #emailsLisInput
            appendTo="body"
            inputStyleClass="table-filter"
            styleClass="p-column-filter"
            [minLength]="3"
            [suggestions]="filteredEmailsList"
            [(ngModel)]="email"
            placeholder="Search"
            (completeMethod)="onSearchEmails($event)"
            (onSelect)="filterTable($event, 'email', 'in')"
            (onClear)="filterTable('', 'email', 'in')"
          ></p-autoComplete>
        </div>
      </th>
      <th>
        <div class="relative autocomplete-icon-align">
          <div class="search-icon absolute" (click)="displayNameInput.focusInput()">
            <i class="pi pi-search"></i>
          </div>
          <p-autoComplete
            #displayNameInput
            appendTo="body"
            inputStyleClass="table-filter"
            styleClass="p-column-filter"
            [minLength]="3"
            [suggestions]="filteredDisplayNamesList"
            [(ngModel)]="displayName"
            placeholder="Search"
            (completeMethod)="onSearchDisplayName($event)"
            (onSelect)="filterTable($event, 'displayName', 'in')"
            (onClear)="filterTable('', 'displayName', 'in')"
          ></p-autoComplete>
        </div>
      </th>
      <th></th>
      <th></th>
      <th>
        <p-dropdown
          styleClass="p-column-filter"
          [options]="activesList"
          [showClear]="true"
          placeholder="Filter"
          (onChange)="filterTable($event.value, 'isApproved', 'eq')"
          [(ngModel)]="active"
          appendTo="body"
        ></p-dropdown>
      </th>
      <th>
        <p-calendar
          appendTo="body"
          styleClass="p-column-filter"
          dateFormat="mm/dd/y"
          selectionMode="range"
          [(ngModel)]="createdOnRange"
          placeholder="Filter"
          [showIcon]="true"
          [readonlyInput]="true"
          (onSelect)="filterTable(createdOnRange, 'createdOn', 'dateRangeWihoutOffset')"
          [showButtonBar]="true"
          (onClearClick)="filterTable(null, 'createdOn', 'dateRangeWihoutOffset')"
        ></p-calendar>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <p>{{ isLoading ? 'Loading... please wait' : 'No record found' }}</p>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-user let-columns="columns" let-expanded="expanded">
    <tr>
      <td style="width: 122px">
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="user"
          class="p-button-text"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>

        <button
          pButton
          pRipple
          type="button"
          class="p-button-text"
          [icon]="'pi pi-pencil'"
          (click)="editUser(user.userId)"
          title="Edit User"
        ></button>

        <button
          pButton
          type="button"
          [icon]="user.isApproved ? 'pi pi-lock' : 'pi pi-unlock'"
          class="p-button-text"
          title="Activate or In-Activate An User. On Activation Password Setup Email Will Be Sent."
          [accessEnableSubmit]="['ExternalUser.Write']"
          [appChangeUserStatusV2]="user"
        ></button>
      </td>
      <td>
        <!-- <div class="flex align-items-center text-truncate expand-group"> -->
          <!-- <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="user"
            class="p-button-text p-py-0"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          ></button> -->
          <!-- <span> -->
            {{ user.email }}
          <!-- </span> -->
        <!-- </div> -->
      </td>
      <td>
        {{ user.displayName }}
      </td>
      <td>
        {{ user.healthPlans?.join(', ')}}
      </td>
      <td>
        {{ user.accountTypeDisplayName }}
      </td>
      <td>
        {{ user.isApproved ? 'Active' : 'Inactive' }}
      </td>
      <td class="tdnowrap">
        {{ user.createdOn | utcToLocal }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-user>
    <tr>
      <!-- <td colspan="7" class="p-3"> -->
      <td colspan="7">
        <!-- <div> -->
          <app-external-user-provides [userId]="user.userId"></app-external-user-provides>
        <!-- </div> -->
      </td>
    </tr>
  </ng-template>
</p-table>
