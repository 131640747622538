<div class="p-3">
  <app-user-profile-widget
    (userDataChange)="updateUserData($event)"
    [accountTypeNew]="accountTypeNew"
    [userAccountInformationChanged]="userAccountInformationChanged"
    (disableRadioButtons)="ondiableradionbtns($event)"
  >
  </app-user-profile-widget>
</div>
<div class="px-3">
  <app-account-information-widget
    [userId]="userId"
    [userData]="userData"
    (accountTypeNew)="onChangeAccountType($event)"
    (userAccountInformationChanged)="onUserAccountInformationChanged()"
    [disableRadioButtons] = "disableRadioButtons"
  >
  </app-account-information-widget>
</div>
<div class="p-3">
  <app-audit-log-widget [userId]="userId"></app-audit-log-widget>
</div>
