import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DualAccountTypes } from '@core/constants/pac-account-type';
import { IExternalUser } from '@core/model';
import { TenantsService } from '@core/services';
import { SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { getAccountTypeOptions } from '@utils/account-type-utilities';
import { WccAccountTypes } from '@core/enums/wcc-account-type.enum';
import { ChappAccountTypes } from '@core/enums/chapp-account-type.enum';
import { TenantNames } from '@mynexus/mynexus-ui-lib';
import { CidAccountTypes } from '@core/constants';
import { UtilitiesService } from '@core/services/utilities.service';

interface HealthPlanSelectItemValue {
  tenantId: string,
  healthPlanName: string
}

@Component({
  selector: 'app-account-information-widget',
  templateUrl: './account-information-widget.component.html',
  styleUrls: ['./account-information-widget.component.scss']
})
export class AccountInformationWidgetComponent implements OnInit, OnDestroy, OnChanges {
  private userIdValue = '';
  private subs: Subscription = new Subscription();
  
  @Input() disableRadioButtons : boolean = false;

  @Input() set userId(value: string) {
    this.userIdValue = value;
  }

  get userId(): string {
    return this.userIdValue;
  }

  @Input() userData!: IExternalUser;

  @Output() accountTypeNew = new EventEmitter<string>();
  @Output() userAccountInformationChanged = new EventEmitter();
  isLoading = false;
  healthPlans: SelectItem<HealthPlanSelectItemValue>[] = [];
  applicableHealthPlans: SelectItem<HealthPlanSelectItemValue>[] = [];

  healthPlan = '';
  applicableAccountTypes: SelectItem[] = [];
  accountTypes: SelectItem[] = [];
  showAssignProvidersWidget = true;
  hasSelectedProviders = false;
  shouldShowCentralizedDepartmentToggle = false;
  centralizedDepartment = '';
  accountType = '';
  showCentralizedDepartmentToggleForHP = true;

  constructor(
    private tenantsService: TenantsService,
    private utilitiesService: UtilitiesService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.subs = forkJoin({
      accountTypes: this.utilitiesService.accountTypes,
      tenants: this.tenantsService.getTenantsForRegistration(),
    })
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(({accountTypes, tenants}) => {
        this.healthPlans = [];
        this.accountTypes = [];

        if (tenants && accountTypes) {
          this.healthPlans = tenants.map(el => ({ label: el.name, value: { healthPlanName:el.name, tenantId: el.tenantId} }));
          this.applicableHealthPlans = this.healthPlans.filter(hp => this.healthPlanSupportsAccountType(hp.label, this.accountType));
          this.accountTypes = getAccountTypeOptions(accountTypes);
          this.applicableAccountTypes =
            this.accountTypes.filter(at => !this.healthPlan || this.healthPlanSupportsAccountType(this.healthPlan, at.value));
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.userData.currentValue) {
      return;
    }
    
    const { accountType, centralizedDepartment } = changes.userData.currentValue;

    if (accountType) {
      this.isAssignProvidersWidgetShown(accountType);
      this.applicableHealthPlans = this.healthPlans.filter(healthPlan => this.healthPlanSupportsAccountType(healthPlan.value.healthPlanName, accountType));
      this.shouldShowCentralizedDepartmentToggle = !!CidAccountTypes.find(cidAccountType => cidAccountType === accountType);
    }

    this.centralizedDepartment = centralizedDepartment;
    this.accountType = accountType;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onChangeHealthPlan($event: HealthPlanSelectItemValue): void {
    this.applicableAccountTypes = this.accountTypes.filter(at => this.healthPlanSupportsAccountType($event.healthPlanName, at.value));
  }

  onChangeAccountType(event: string): void {
    if (event) {
      this.applicableHealthPlans = this.healthPlans.filter(hp => this.healthPlanSupportsAccountType(hp.value.healthPlanName, event));
      this.accountTypeNew.emit(event);
      this.isAssignProvidersWidgetShown(event);
    }
  }

  onSelectProvider(event: boolean): void {
    this.hasSelectedProviders = event;
  }

  onUserProvidersChanged(): void {
    this.centralizedDepartment = '';
    this.userAccountInformationChanged.emit();
  }

  isAssignProvidersWidgetShown(value: string): void {
    this.showAssignProvidersWidget = !DualAccountTypes.includes(value);
  }

  private healthPlanSupportsAccountType(tenantName?: string, accountType?: string): boolean {

    if (this.checkAccountTypeVisibility(accountType)) return false;

    if (this.checkAccountTypeRestriction(tenantName, accountType)) return false;

    return true;

  }

  private checkAccountTypeVisibility(accountType?: string): boolean {
    const hiddenPlansList = [WccAccountTypes.WccProviderAdmin, WccAccountTypes.WccClinician];

    const isHiddenPlan = (type?: string) => {
      return hiddenPlansList.findIndex(t => type?.localeCompare(t, undefined, { sensitivity: 'base' }) === 0 ) !== -1;
    };

    return isHiddenPlan(accountType);
  }

  private checkAccountTypeRestriction(tenantName?: string, accountType?: string): boolean {
    const restrictedSupportAccountPlans = [
      ChappAccountTypes.ChappProvider,
      ChappAccountTypes.SpecialtyProgramClinician,
      ChappAccountTypes.SpecialtyProgramProviderAdmin,
    ];

    const excludedTenants = [TenantNames.Anthem, TenantNames.Regence, TenantNames.Wellpoint];

    const isRestrictedSupportType = (type?: string) => {
      return restrictedSupportAccountPlans.findIndex(t => type?.localeCompare(t, undefined, { sensitivity: 'base' }) === 0) !== -1;
    };

    const isExcludedTenant = (name?: string) => {
      return excludedTenants.findIndex(t => name?.localeCompare(t, undefined, { sensitivity: 'base' }) === 0) !== -1;
    };

    return isRestrictedSupportType(accountType) && !isExcludedTenant(tenantName)
  }

}