// tslint:disable: no-any
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_PLATFORM_URL, API_URL } from '@core/config/config.service';
import { GraphRoutes, UrlConstants, UserManagementRoutes } from '@core/constants';
import { HandleError, HttpErrorHandler, HttpUtility } from '@core/http';
import {
    IExternalUserListItem,
    IInternalUserItem,
    IProvider,
    IProviderSearchResult,
    IRelevantDataProviders,
    IRelevantDataUserProviders,
    IUserLog,
    IInternalUser,
    IExternalUser
} from '@core/model';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UsersService {
  public handleError: HandleError;
  private relevantDataByUserId = new Map<string, IRelevantDataUserProviders>();
  private providersByUserId = new Map<string, IProviderSearchResult<IProvider>>();

  constructor(
    private httpUtility: HttpUtility,
    private httpErrorHandler: HttpErrorHandler,
    @Inject(API_URL) private readonly apiBaseUrl: string,
    @Inject(API_PLATFORM_URL) private readonly apiPlatformBaseUrl: string) {
    this.handleError = this.httpErrorHandler.createHandleError('UserService');
  }

  public getExternalUsers(queryParams: string): Observable<IProviderSearchResult<IExternalUserListItem>> {
    return this.httpUtility.get<IProviderSearchResult<IExternalUserListItem>>(
      `${this.apiPlatformBaseUrl}/${UserManagementRoutes.GetUsers}?${queryParams}`
    )
      .pipe(
        catchError(this.handleError('getExternalUsers', {} as IProviderSearchResult<IExternalUserListItem>))
      );
  }

  public getExternalUsersRelevantData(): Observable<any> {
    return this.httpUtility.get<any>(
      `${this.apiBaseUrl}/${UrlConstants.usersRelevantData}`
    )
      .pipe(
        catchError(this.handleError('getExternalUsersRelevantData', {} as any))
      );
  }

  public getInternalUsers(query: string): Observable<IProviderSearchResult<IInternalUserItem>> {
    return this.httpUtility.get<IProviderSearchResult<IInternalUserItem>>(
      `${this.apiPlatformBaseUrl}/${GraphRoutes.GetUsers}?${query}`
    )
      .pipe(
        catchError(this.handleError('search', {} as IProviderSearchResult<IInternalUserItem>))
      );
  }

  public getUserLog(userId: string): Observable<IUserLog[] | undefined> {
    const url = `${this.apiBaseUrl}/${UrlConstants.users}/${userId}/auditLogs`;
    return this.httpUtility.get<IUserLog[]>(url)
      .pipe(
        catchError(this.handleError('getUserLog', []))
      );
  }

  public updateUserActiveStatus(userId: string, isActive: boolean): Observable<void> {
    return this.httpUtility.put<void>(
      `${this.apiPlatformBaseUrl}/${GraphRoutes.UpdateUser}/${userId}`,
      { 'userId': userId, 'isApproved': isActive }
    )
      .pipe(
        catchError(this.handleError('updateUserActiveStatus', undefined))
      );
  }

  public getProvidersDataByUserId(userId: string): Observable<IProviderSearchResult<IProvider>> {
    return of(this.providersByUserId.get(userId)!);
  }

  public hasProvidersDataByUserId(userId: string): boolean {
    return this.providersByUserId.has(userId);
  }

  public setProvidersDataByUserId(userId: string, providers: IProviderSearchResult<IProvider>): void {
    this.providersByUserId.set(userId, providers);
  }

  public getProvidersByUserId(userId: string, queryParams: string): Observable<IProviderSearchResult<IProvider>> {
    return this.httpUtility.post<IProviderSearchResult<IProvider>>(
      `${this.apiBaseUrl}/${UrlConstants.users}/${userId}/${UrlConstants.userProviders}`,
      queryParams
    )
      .pipe(
        catchError(this.handleError('getProvidersByUserId', {} as IProviderSearchResult<IProvider>))
      );
  }

  public getRelevantDataByUserId(userId: string): Observable<IRelevantDataUserProviders> {
    return of(this.relevantDataByUserId.get(userId)!);
  }

  public hasRelevantDataByUserId(userId: string): boolean {
    return this.relevantDataByUserId.has(userId);
  }

  public setRelevantDataByUserId(userId: string, relevantData: IRelevantDataUserProviders): void {
    this.relevantDataByUserId.set(userId, relevantData);
  }

  public getProvidersByUserIdRelevantData(userId: string): Observable<IRelevantDataUserProviders> {
    return this.httpUtility.get<IRelevantDataUserProviders>(
      `${this.apiBaseUrl}/${UrlConstants.users}/${userId}/${UrlConstants.userProviders}/RelevantData`
    )
      .pipe(
        catchError(this.handleError('getProvidersByUserIdRelevantData', {} as IRelevantDataUserProviders))
      );
  }

  public getUserById(userId: string): Observable<IExternalUser> {
    const url = `${this.apiPlatformBaseUrl}/${UserManagementRoutes.GetExternalUser}/${userId}`;
    return this.httpUtility.get<IExternalUser>(url)
      .pipe(
        catchError(this.handleError('getUserById', {} as IExternalUser))
      );
  }

  public saveUserById(userId: string, user: IExternalUser): Observable<IExternalUser> {
    const url = `${this.apiPlatformBaseUrl}/${UserManagementRoutes.UpdateExternalUser}/${userId}`;
    return this.httpUtility.put<IExternalUser>(url, user)
      .pipe(
        catchError(this.handleError('saveUserById', {} as IExternalUser))
      );
  }

  public getProviderAccounts(tenantId: string, queryParams: string = ''): Observable<IProviderSearchResult<IProvider>> {
    const url = `${this.apiBaseUrl}/${UrlConstants.providerManagement}/GetProviderAccountsForUserManagement/${tenantId}`;

    return this.httpUtility.post<any>(url, queryParams)
      .pipe(
        catchError(this.handleError('GetProviderAccountsForUserManagement', {} as IProviderSearchResult<IProvider>))
      );
  }

  public getProviderAccountsRelevantData(tenantId: string, queryParams: string = ''): Observable<IRelevantDataProviders> {

    const url = `${this.apiBaseUrl}/${UrlConstants.providerManagement}/GetProviderAccountsRelevantDataForUserManagement/${tenantId}`;

    return this.httpUtility.post<IRelevantDataProviders>(url, queryParams)
      .pipe(
        catchError(this.handleError('GetProviderAccountsRelevantDataForUserManagement', {} as IRelevantDataProviders))
      );
  }

  public getParentCompanyNames(tenantId: string, productName: string = ''): Observable<string[]> {
    const url = `${this.apiBaseUrl}/${UrlConstants.providerManagement}/GetParentCompanyNames/${tenantId}?productName=${productName}`;
    return this.httpUtility.get<string[]>(url)
      .pipe(
        catchError(this.handleError('getParentCompanyNames', []))
      );
  }

  public assignProvidersToUser(userId: string, body: any): Observable<IProvider[]> {
    const url = `${this.apiBaseUrl}/${UrlConstants.users}/${userId}/${UrlConstants.providers}`;
    return this.httpUtility.post<IProvider[]>(url, body)
      .pipe(
        catchError(this.handleError('assignProvidersToUser', []))
      );
  }

  public removeProvidersFromUser(userId: string, providerIds: string[]): Observable<any> {
    const httpOptionsDelete = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        responseType: 'json'
      }),
      body: providerIds
    };
    const url = `${this.apiBaseUrl}/${UrlConstants.users}/${userId}/${UrlConstants.providers}`;
    return this.httpUtility.delete<any>(url, httpOptionsDelete);
  }

  public getInternalUserById(userId: string): Observable<IInternalUser> {
    const url = `${this.apiPlatformBaseUrl}/${GraphRoutes.GetUser}/${userId}`;
    return this.httpUtility.get<IInternalUser>(url)
      .pipe(
        catchError(this.handleError('getInternalUserById', {} as IInternalUser))
      );
  }

  public saveInternalUserById(userId: string, user: IInternalUser): Observable<IInternalUser> {
    const url = `${this.apiPlatformBaseUrl}/${GraphRoutes.UpdateUser}/${userId}`;
    return this.httpUtility.put<IInternalUser>(url, user)
      .pipe(
        catchError(this.handleError('saveUserById', {} as IInternalUser))
      );
  }

  public GetUserEnrolledDevices(userId: string): Observable<boolean> {
    const url = `${this.apiPlatformBaseUrl}/${GraphRoutes.EnrolledDevices}/${userId}`;
    return this.httpUtility.get<boolean>(url);
  }

  public DeleteUserEnrolledDevices(userId: string): Observable<boolean> {
    const url = `${this.apiPlatformBaseUrl}/${GraphRoutes.DeleteEnrolledDevices}/${userId}`;
    return this.httpUtility.delete<boolean>(url);
  }
}
