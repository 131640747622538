import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IExternalUser } from '@core/model';
import { UsersService } from '@core/services';
import { UtilitiesService } from '@core/services/utilities.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-profile-widget',
  templateUrl: './user-profile-widget.component.html',
  styleUrls: ['./user-profile-widget.component.scss']
})
export class UserProfileWidgetComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();

  @Input() userId = '';
  @Input() set accountTypeNew(value: string) {
    this.reloadUserData(value);
  }
  @Input() set userAccountInformationChanged(value: number) {
    if (value > 0) {
      this.reloadUserData(value);
    }
  }

  @Output() userDataChange = new EventEmitter<IExternalUser>();
  @Output() disableRadioButtons = new EventEmitter<boolean>();
  showWarningMessage : boolean = false;
  user!: IExternalUser;
  users: IExternalUser[] = [];
  isLoading = false;
  hasDevices = false;
  // tslint:disable-next-line: no-any
  initialFormValue!: any;
  isFormChanged = false;
  dateTime!: string;
  form!: FormGroup;

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private userService: UsersService,
    private router: Router,
    private confirmService: ConfirmationService,
    private utilitiesService: UtilitiesService, 
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.userId = params.uid;
      });
    this.getUserById(this.userId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subs.unsubscribe();
  }

  reloadUserData(value: number | string): void {
    if (value && this.userId) {
      this.getUserById(this.userId);
    }
  }

  onSave(): void {
    const data = { ...this.form.value };
    const externalUser = {
      isApproved: this.user.isActive,
      firstName: data.givenName,
      lastName: data.surname,
      phone: data.telephoneNumber,
      phoneExtension: data.telephoneNumberExtension,
      fax: data.facsimileTelephoneNumber,
      displayName: `${data.givenName} ${data.surname}`
    } as IExternalUser;
    
    this.userService.saveUserById(this.userId, externalUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          key: 'saved',
          detail: `All changes were saved successfully`,
          life: 5000
        });
        this.getUserById(this.userId);
      });
  }

  onCancel(): void {
    if (this.form) {
      this.form.get('givenName')?.setValue(this.user.firstName);
      this.form.get('surname')?.setValue(this.user.lastName);
      this.form.get('otherMails')?.setValue(this.user.email);
      this.form.get('telephoneNumber')?.setValue(this.user.phone);
      this.form.get('telephoneNumberExtension')?.setValue(this.user.phoneExtension);
      this.form.get('facsimileTelephoneNumber')?.setValue(this.user.fax);
    }
  }

  getUserById(userId: string): void {
    this.isLoading = true;
    this.subs = this.userService.getUserById(userId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.isLoading = false)
      )
      .subscribe(user => {
        this.user = user;
        this.dateTime = user.modifiedOn ? user.modifiedOn : user.createdOn;
        this.user.isActive = this.user.isApproved;
        this.users = [user];
        this.userDataChange.emit(this.user);
        this.createForm(this.user);
        this.checkForEnrolledDevices(this.user);
      
      });
  }

  createForm(user: IExternalUser): void {
    if(user){
      this.form = new FormGroup({
        givenName: new FormControl(user.firstName, Validators.required),
        surname: new FormControl(user.lastName, Validators.required),
        otherMails: new FormControl({ disabled: true, value: user.email }),
        telephoneNumber: new FormControl(user.phone, [
          Validators.minLength(10),
          Validators.required,
          Validators.pattern('[0-9]{10}')
        ]),
        telephoneNumberExtension: new FormControl(user.phoneExtension || '', [
          Validators.maxLength(9),
        ]),
        facsimileTelephoneNumber: new FormControl(user.fax, [
          Validators.minLength(10),
          Validators.required,
          Validators.pattern('[0-9]{10}')
        ]),
        accountEnabled: new FormControl(user.isActive)
      });
      this.initialFormValue = Object.assign({}, this.form.value);
      this.isFormChanged = false;
      this.utilitiesService.hasUnsavedChanges = !this.canSaveUser;
  
      this.form.valueChanges.subscribe(() => this.handleFormChange());
      }
  }

  handleFormChange(): void {
    this.isFormChanged = this.formChanged();
    this.utilitiesService.hasUnsavedChanges = !this.canSaveUser;
  }

  formChanged(): boolean {
    return (
      Object.keys(this.initialFormValue).filter(
        key => this.initialFormValue[key] !== this.form.value[key]
      ).length > 0
    );
  }

  checkForEnrolledDevices(user: IExternalUser) {
    this.userService.GetUserEnrolledDevices(user.userId).subscribe({
      next: res => {
        this.hasDevices = res;
      }
    })
  }

  deleteDevices() {
    this.confirmService.confirm({
      header: 'Reset MFA Device(s)',
      message: 'Resetting the MFA device will remove all currently enrolled devices.\nThe user will need to enroll another device to sign in via the Authenticator App.\n\nAre you sure that you want to continue?',
      acceptLabel: 'Reset MFA Device(s)',
      rejectLabel: 'Cancel',
      dismissableMask: true,
      accept: () => {
        this.deleteEnrolledDevices();
      }
    })
  }

  private deleteEnrolledDevices() {
    this.userService.DeleteUserEnrolledDevices(this.user.userId).subscribe({
      next: res => {
        if (res) {
          this.messageService.add({
            severity: 'success',
            summary: 'Reset MFA Device',
            key: 'saved',
            detail: `The MFA device has been successfully reset.`,
            life: 5000
          });
          this.hasDevices = false;
        }
        // In theory, we should ever get a false response, as that generates a 404 from the API
        else {
          this.messageService.add({
            severity: 'error',
            summary: 'Cannot Reset MFA Device',
            key: 'error',
            detail: `An Error has occurred when resetting the MFA device. Please try again later.`,
            life: 5000
          });
        }
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Cannot Reset MFA Device',
          key: 'error',
          detail: `An Error has occurred when resetting the MFA device. Please try again later.`,
          life: 5000
        });
      }
    })
  }

  goBack(): void {
    this.router.navigate(['users']);
  }

  get canSaveUser(): boolean {
    return !(this.isFormChanged && this.form.valid);
  }
}
