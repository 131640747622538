import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  CidAccountTypes,
  DualAccountTypes,
  PacAccountTypes,
  PacAccountTypesValue,
  StarsAccountTypesValue
} from '@core/constants';
import { DmeposAccountTypes } from '@core/constants/dmepos-account-type';
import { DmeposAccountTypesValue } from '@core/constants/dmepos-account-types-value';
import { PAC_ACCOUNT_TYPE_ENUM } from '@core/enums/pac-account-type.enum';
import { STARS_ACCOUNT_TYPE_ENUM } from '@core/enums/stars-account-type.enum';
import { TenantsService } from '@core/services';
import { MessageService, SelectItem } from 'primeng/api';
import { RadioButtonClickEvent } from 'primeng/radiobutton';

@Component({
  selector: 'app-account-switch',
  templateUrl: './account-switch.component.html',
  styleUrls: ['./account-switch.component.scss']
})
export class AccountSwitchComponent {
  private readonly anthemId = '548bead1-bdab-e811-bce7-0003ff21d46b';
  private readonly regenceId = '81c8042e-6c6d-4aca-9d29-59fb3ef734f5';
  private readonly WellpointId = "bb390147-666b-4004-98b9-27d7380dc19d";
  private accountValue!: string;
  private accountTypesValue!: SelectItem[];

  @Input() userId = '';

  @Input() hasSelectedProviders = false;
  @Input() disableRadioButtons: boolean = false;

  @Input() set tenantId(value: string) {
    this.tenant = value;

    switch (value) {
      case this.anthemId:
      case '':
        this.availableAccountTypes = this.accountTypesValue;
        break;
      case this.regenceId:
        this.availableAccountTypes = this.accountTypesValue.filter(
          (e: SelectItem) =>
            [...PacAccountTypesValue, 'Home Health Agency', 'Physician', 'Health Plan Case Manager',
              'Hospital Case Manager/Discharge Planner'].includes(e.label as PAC_ACCOUNT_TYPE_ENUM | string)
        )
        break;
      case this.WellpointId:
          this.availableAccountTypes = this.accountTypesValue.filter((e: SelectItem) =>
            [...PacAccountTypesValue,
              'Physician',
              'Health Plan Case Manager',
            ].includes(e.label as PAC_ACCOUNT_TYPE_ENUM | string)
        )
        break;
      default:
        this.availableAccountTypes = this.accountTypesValue.filter(
          (e: SelectItem) =>
            ![...PacAccountTypesValue, ...StarsAccountTypesValue, ...DmeposAccountTypesValue].includes(e.label as STARS_ACCOUNT_TYPE_ENUM)
        )
        break;
    }

    if (this.tenant && this.tenant == this.regenceId) this.availableAccountTypes = this.availableAccountTypes.filter(x => x.label != "Home Health Billing Office" && x.label != "Home Health Admin");

    const centralizedDepartmentToggleForHP = !!this.availableAccountTypes.find(accountType => CidAccountTypes.find(cidAccountType => cidAccountType === accountType.value));

    this.showCentralizedDepartmentToggleForHP.emit(centralizedDepartmentToggleForHP);
  }

  @Input() set account(value: string) {
    this.accountValue = value;
    this.buildAccountSwitchForm();
  }

  get account(): string {
    return this.accountValue;
  }

  @Input() set accountTypes(value: SelectItem[]) {
    this.accountTypesValue = value;
  }

  get accountTypes(): SelectItem[] {
    return this.accountTypesValue;
  }

  @Output() accountTypeNew = new EventEmitter<string>();
  @Output() showCentralizedDepartmentToggleForHP = new EventEmitter<boolean>();

  tenant!: string;
  showDialog = false;
  type!: SelectItem;
  accountSwitchForm!: FormGroup;
  newAccount!: string;
  availableAccountTypes!: SelectItem[];

  constructor(private messageService: MessageService, private tenantsService: TenantsService) {}

  buildAccountSwitchForm(): void {
    this.accountSwitchForm = new FormGroup({
      accountTypeControl: new FormControl(this.account)
    });
  }
  
  accountTypeChange(event: RadioButtonClickEvent){
    const accountType = event?.value;
    const isChangedToFromDualAccountTypes =
      (!DualAccountTypes.includes(this.accountValue) && DualAccountTypes.includes(accountType)
      || DualAccountTypes.includes(this.accountValue) && !DualAccountTypes.includes(accountType));
    const isChangedToFromPacAccountTypes =
      (!PacAccountTypes.includes(this.accountValue) && PacAccountTypes.includes(accountType))
      || (PacAccountTypes.includes(this.accountValue) && !PacAccountTypes.includes(accountType));
    const isChangedToFromDmeposTypes =
      (!DmeposAccountTypes.includes(this.accountValue) && DmeposAccountTypes.includes(accountType))
      || (DmeposAccountTypes.includes(this.accountValue) && !DmeposAccountTypes.includes(accountType));
    
    if (this.hasSelectedProviders && (isChangedToFromDualAccountTypes || isChangedToFromPacAccountTypes || isChangedToFromDmeposTypes)) {
      this.displayWarningMessage();
    } else {
      this.showDialogWindow(accountType);
    }
  }

  showDialogWindow(val: string): void {
    if (this.account === val) {
      return;
    }
    if (this.tenant && val) {
      this.showDialog = true;
      this.newAccount = val;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        key: 'warn',
        detail: 'Please choose a Health Plan',
        life: 5000
      });
      this.accountSwitchForm
        .get('accountTypeControl')
        ?.setValue(this.account, { emitEvent: false });
    }
  }

  proceedUpdate(): void {
    this.showDialog = false;

    const newAccountType = {
      accountTypeName: this.newAccount,
      tenantId: this.tenant
    };
    this.accountSwitchForm
      .get('accountTypeControl')
      ?.setValue(this.newAccount, { emitEvent: false });
    this.tenantsService.updateAccountType(this.userId, newAccountType).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        key: 'switched',
        detail: 'Account type changed',
        life: 5000
      });
      this.accountTypeNew.emit(this.newAccount);
    });
  }

  cancelUpdate(): void {
    this.showDialog = false;
    this.accountSwitchForm
      .get('accountTypeControl')
      ?.setValue(this.account, { emitEvent: false });
  }

  private displayWarningMessage(): void {
    this.messageService.add({
      severity: 'warn',
      summary: 'Warning',
      key: 'warn',
      detail: 'Please remove the selected providers before changing the Account Type',
      life: 5000
    });
    this.accountSwitchForm
      .get('accountTypeControl')
      ?.setValue(this.account, { emitEvent: false });
  }
}
